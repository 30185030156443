/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, useForm } from "react-hook-form";
import { z } from "zod";
import {
  Button,
  Dropdown,
  Label,
  Sidebar,
  Spacer,
  Textarea,
  theme,
  Text,
} from "@nordcloud/gnui";
import {
  EventAction,
  EventActionAttempt,
  EventActionBatch,
  ResourceExecutionStatusManual,
} from "~/generated/graphql";
import { FormGroup, stringRequired } from "~/components/Forms";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { useChangeResourceExecutionStatus } from "~/views/events/hooks";
import { useEventDetailsTab } from "../../../EventDetailsTabProvider";

type Props = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
  resourceName: string;
  changeStatusDetails: {
    eventId: string;
    eventActionId: string;
    eventActionResourceStateId: string;
  };
};

export function ChangeResourceStatusSidebar({
  isOpen,
  close,
  changeStatusDetails,
  resourceName,
}: Props) {
  const MAX_ANNOTATION_LENGTH = 500;
  const selectStatusLabel = "Select Status";
  const statusChangeCommentLabel = "Status Change Comment";

  enum FormField {
    STATUS = "status",
    ANNOTATION = "annotation",
  }

  type FormData = {
    [FormField.STATUS]: ResourceExecutionStatusManual;
    [FormField.ANNOTATION]: string;
  };

  const schema = z.object({
    annotation: stringRequired(statusChangeCommentLabel).max(
      MAX_ANNOTATION_LENGTH,
      {
        message: `${statusChangeCommentLabel} is too long (max: ${MAX_ANNOTATION_LENGTH} characters)`,
      }
    ),
    status: z.nativeEnum(ResourceExecutionStatusManual),
  });

  const statusOptions = Object.values(ResourceExecutionStatusManual).map(
    (option) => {
      return {
        value: option,
        label: option.split("_")[1],
      };
    }
  );

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      status: ResourceExecutionStatusManual.ManualSuccess,
      annotation: "",
    },
  });

  const { changeResourceExecutionStatus, loading } =
    useChangeResourceExecutionStatus({ onSuccess: close });

  const onSubmit = async (formData: FormData) => {
    await changeResourceExecutionStatus({
      ...{
        status: formData[FormField.STATUS],
        annotation: formData[FormField.ANNOTATION],
      },
      ...changeStatusDetails,
    });
  };

  // update status after closing sidebar
  const { event } = useEvent();
  const { attempt, eventAction, setAttempt, setEventAction } =
    useEventDetailsTab();

  const findEventAction =
    event?.actions?.find(
      (action: EventAction) => action.id === eventAction?.id
    ) ||
    event?.actionBatches
      ?.flatMap((batch: EventActionBatch) => batch.actions)
      .find((action: EventAction) => {
        return action.id === eventAction?.id;
      });

  useEffect(() => {
    if (!isOpen) {
      setEventAction?.(findEventAction);
    }
  }, [findEventAction, isOpen, setEventAction]);

  const findAttempt = findEventAction.attempts?.find(
    (a: EventActionAttempt) => a.id === attempt?.id
  );

  useEffect(() => {
    if (!isOpen) {
      setAttempt?.(findAttempt);
    }
  }, [findAttempt, isOpen, setAttempt]);

  return (
    <Sidebar title="Change Status" isOpen={isOpen} onClick={close}>
      <Text
        align="left"
        size="sm"
        color={theme.color.text.text02}
        mb={theme.spacing.spacing04}
      >
        {`You are about to change the ${resourceName} status manually.`}
      </Text>
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup error={errors[FormField.STATUS]}>
          <Label name={selectStatusLabel} htmlFor={FormField.STATUS} required />
          <Controller
            control={control}
            name={FormField.STATUS}
            render={({ field: { onChange, value } }) => {
              return (
                <Dropdown
                  css={{ width: "70%" }}
                  name={selectStatusLabel}
                  options={statusOptions}
                  onChange={onChange}
                  value={value}
                />
              );
            }}
          />
        </FormGroup>

        <FormGroup error={errors[FormField.ANNOTATION]}>
          <Label
            name={statusChangeCommentLabel}
            htmlFor={FormField.ANNOTATION}
            required
          />
          <Textarea
            css={{ minHeight: "8rem" }}
            id={FormField.ANNOTATION}
            {...register(FormField.ANNOTATION)}
          />
        </FormGroup>
        <Spacer height={theme.spacing.spacing02} />
        <Button initialState={loading ? "loading" : "success"}>Apply</Button>
      </form>
    </Sidebar>
  );
}
