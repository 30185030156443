/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import {
  EventActionResourceState,
  EventActionScope,
  EventStatus,
  ExecutionStatus,
  GetEventDocument,
  GetEventsDocument,
  GetIncomingEventDocument,
  GetIncomingEventsDocument,
  IncomingEventStatus,
} from "~/generated/graphql";
import { inflect, isEmpty, isNotEmpty } from "~/tools";
import { IconName } from "~/types/IconName";
import { TooltipColor } from "~/types/TooltipColor";
import { EventEntity } from "./types";

export type Status = {
  description: string;
  icon: IconName;
  color: () => string;
  tooltipColor?: TooltipColor;
};

export function getEventStatus(
  status?: string,
  actionsLength?: number
): Status {
  switch (status) {
    case EventStatus.WaitingForApproval:
      return {
        description: `Event has unapproved ${inflect("action")(
          actionsLength ?? 0
        )}`,
        icon: "pausedFilled",
        color: () => `${theme.color.support.purple}`,
        tooltipColor: TooltipColor.accent,
      };
    case EventStatus.Failed:
      return {
        description: "Failed",
        icon: "cancelFilled",
        color: () => `${theme.color.background.error}`,
        tooltipColor: TooltipColor.danger,
      };
    case IncomingEventStatus.Upcoming:
      return {
        description: "Upcoming",
        icon: "waitingFilled",
        color: () => `${theme.color.support.grey}`,
      };

    case EventStatus.InProgress:
      return {
        description: "In Progress",
        icon: "inProgressFilled",
        color: () => `${theme.color.background.info}`,
        tooltipColor: TooltipColor.notification,
      };

    case EventStatus.PartialSuccess:
      return {
        description: "Partially Successful",
        icon: "warningFilled",
        color: () => `${theme.color.background.warning}`,
        tooltipColor: TooltipColor.warning,
      };
    case IncomingEventStatus.Revoked:
      return {
        description: "Revoked",
        icon: "cancelFilled",
        color: () => `${theme.colors.primary}`,
      };

    case EventStatus.Success:
      return {
        description: "Successful",
        icon: "successFilled",
        color: () => `${theme.color.background.success}`,
        tooltipColor: TooltipColor.success,
      };

    default:
      return {
        description: "N/A",
        icon: "playFilled",
        color: () => `${theme.color.support.grey}`,
      };
  }
}

export function getEventEntityStatus(status?: string): Status {
  switch (status) {
    case ExecutionStatus.ApprovalRequired:
      return {
        description: "Unapproved",
        icon: "pausedFilled",
        color: () => `${theme.color.support.purple}`,
        tooltipColor: TooltipColor.accent,
      };
    case ExecutionStatus.ApprovalTimeout:
      return {
        description: "Approval Timed out",
        icon: "pausedFilled",
        color: () => `${theme.color.background.error}`,
        tooltipColor: TooltipColor.danger,
      };
    case ExecutionStatus.Failed:
      return {
        description: "Failed",
        icon: "cancelFilled",
        color: () => `${theme.color.background.error}`,
        tooltipColor: TooltipColor.danger,
      };

    case ExecutionStatus.InProgress:
      return {
        description: "In Progress",
        icon: "inProgressFilled",
        color: () => `${theme.color.background.info}`,
        tooltipColor: TooltipColor.notification,
      };

    case ExecutionStatus.PartialSuccess:
      return {
        description: "Partially Successful",
        icon: "warningFilled",
        color: () => `${theme.color.background.warning}`,
        tooltipColor: TooltipColor.warning,
      };

    case ExecutionStatus.Success:
      return {
        description: "Successful",
        icon: "successFilled",
        color: () => `${theme.color.background.success}`,
        tooltipColor: TooltipColor.success,
      };
    case ExecutionStatus.Timeout:
      return {
        description: "Timeout",
        icon: "timeOutFilled",
        color: () => `${theme.color.background.warning}`,
        tooltipColor: TooltipColor.warning,
      };
    case ExecutionStatus.Upcoming:
      return {
        description: "Upcoming",
        icon: "waitingFilled",
        color: () => `${theme.color.support.grey}`,
      };
    case IncomingEventStatus.Revoked:
      return {
        description: "Revoked",
        icon: "cancelFilled",
        color: () => `${theme.colors.primary}`,
      };
    default:
      return {
        description: "N/A",
        icon: "playFilled",
        color: () => `${theme.color.support.grey}`,
      };
  }
}

export function convertMinutesToTime(minutes: number): string {
  const hours = Math.floor(minutes / 60);
  const minutesRemainder = minutes % 60;
  const HH = hours.toString().padStart(2, "0");
  const mm = minutesRemainder.toString().padStart(2, "0");
  return `${HH}:${mm}`;
}

export enum RefetchOptions {
  EVENTS = "EventsDocument",
  INCOMING_EVENTS = "GetIncomingEventsDocument",
  EVENT = "GetEventDocument",
  INCOMING_EVENT = "GetIncomingEventDocument",
}

export const refetchQueryHelper = (query: RefetchOptions) => {
  switch (query) {
    case RefetchOptions.EVENTS:
      return GetEventsDocument;
    case RefetchOptions.INCOMING_EVENTS:
      return GetIncomingEventsDocument;
    case RefetchOptions.EVENT:
      return GetEventDocument;
    case RefetchOptions.INCOMING_EVENT:
      return GetIncomingEventDocument;
    default:
      throw new Error("unsupported refetch option");
  }
};

export const isResourceScope = (eventAction: EventEntity) => {
  return eventAction?.scope === EventActionScope.Resource;
};

export const shouldAwaitResourceStates = (
  eventEntity: EventEntity,
  resourceStates: EventActionResourceState[]
) => {
  return (
    isResourceScope(eventEntity) &&
    isNotEmpty(eventEntity.resourceGroups ?? []) &&
    (eventEntity.globalState?.status === ExecutionStatus.Upcoming ||
      eventEntity.globalState?.status === ExecutionStatus.InProgress) &&
    isEmpty(resourceStates)
  );
};
