/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { theme } from "@nordcloud/gnui";
import {
  EventActionAttempt,
  useEventActionStatusChangedSubscription,
} from "~/generated/graphql";
import { EventToggleItemBox } from "~/views/events/components/EventToggleItemBox/EventToggleItemBox";
import { getEventEntityStatus } from "~/views/events/helpers";
import { useGetEvent } from "~/views/events/hooks";
import { EventEntity } from "~/views/events/types";
import { useEvent } from "../../../EventProvider";

type Props = {
  item: EventEntity;
  onMouseDown: () => void;
  attempt?: EventActionAttempt;
  handleSelectAttempt: (value: EventActionAttempt) => void;
  eventEntity: EventEntity;
};

export function EventEntityItem({
  item,
  onMouseDown,
  attempt,
  handleSelectAttempt,
  eventEntity,
}: Props) {
  const { event: eventCtx } = useEvent();

  const { refetch: refetchEvent } = useGetEvent({
    eventId: eventCtx.id,
  });

  const attempts =
    item?.attempts && item.attempts?.length > 1 ? item.attempts : [];
  const statusDetails = getEventEntityStatus(item.globalState?.status);

  useEventActionStatusChangedSubscription({
    variables: {
      input: {
        // globalState is recalculated at product api - needs to be refetched when latest attempt status changes
        id: eventEntity.attempts
          ? eventEntity.attempts[eventEntity.attempts.length - 1].id
          : "",
      },
    },
    onData: () => refetchEvent(),
  });

  return (
    <EventToggleItemBox
      item={{
        id: item.id,
        color: item.action?.color ?? theme.color.support.grey,
        name: item.name,
        icon: statusDetails.icon,
        iconColor: statusDetails.color(),
        tooltip: statusDetails.description,
        tooltipColor: statusDetails.tooltipColor,
        onMouseDown: onMouseDown,
        onSelect: handleSelectAttempt,
      }}
      attempts={attempts}
      selectedAttempt={attempt}
      active={item.id === eventEntity.id}
    />
  );
}
