/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "react-awesome-styled-grid";
import { Else, If, Then, When } from "react-if";
import { Box, FlexContainer, Text, theme, Switch } from "@nordcloud/gnui";
import {
  BatchAction,
  BatchedEventAction,
  EventAction,
  EventActionAttempt,
  EventActionBatch,
  EventActionScope,
  useGetEventActionsResourceStatesQuery,
} from "~/generated/graphql";
import { NoData } from "~/components";
import { useToggle } from "~/hooks";
import { isNonEmpty, sortItem } from "~/tools";
import { useEvent } from "~/views/events/EventsDetails/EventProvider";
import { ReportView } from "~/views/events/EventsDetails/Tabs/Details/components/ReportView/ReportView";
import {
  isResourceScope,
  shouldAwaitResourceStates,
} from "~/views/events/helpers";
import { useGetEvent } from "~/views/events/hooks";
import { EventEntity } from "~/views/events/types";
import { WrapMax } from "~/views/plans/styled";
import { EventActionBox } from "./components/ActionBox/EventActionBox";
import { EventActionBatchItem } from "./components/EventActionBatchItem";
import { EventEntityItem } from "./components/EventEntityItem";
import { EventDetailsTabCtxProvider } from "./EventDetailsTabProvider";

export function EventDetailsTab() {
  const { event: eventCtx } = useEvent();

  const actions = eventCtx.actions;
  const actionBatches = eventCtx.actionBatches;

  const hasEntities = isNonEmpty(actions) || isNonEmpty(actionBatches);

  if (hasEntities) {
    return EventActionDetailsTab();
  }

  return <NoData hasIcon message="There are no Actions" />;
}

function EventActionDetailsTab() {
  const { event: eventCtx } = useEvent();
  const actions = eventCtx.actions;
  const batches = eventCtx.actionBatches;

  const [attempt, setAttempt] = useState<EventActionAttempt>();
  const [isReportView, toggleReportView] = useToggle(false);

  const { event } = useGetEvent({
    eventId: eventCtx.id,
  });

  const { startPolling, stopPolling } = useGetEventActionsResourceStatesQuery({
    variables: {
      id: eventCtx.id,
    },
    fetchPolicy: "network-only",
  });

  const sortedItems = useMemo(() => {
    const batchItems = batches.map((batch: EventActionBatch) => ({
      ...batch,
      isBatch: true,
      actions: batch.actions.map((action: BatchedEventAction) => ({
        ...action,
        scope: EventActionScope.Resource,
      })),
    }));
    const actionItems = actions.map((action: EventAction) => ({
      ...action,
      isBatch: false,
    }));
    return sortItem(
      [...batchItems, ...actionItems],
      (a, b) => a.order - b.order
    );
  }, [batches, actions]);

  const [eventEntity, setEventEntity] = useState<EventEntity>(sortedItems[0]);

  const handleSelectAttempt = (value: EventActionAttempt) => setAttempt(value);

  const eventEntityItemProps = {
    ...{
      attempt,
      eventEntity,
      handleSelectAttempt,
    },
  };

  const updatedResourceStates = useMemo(() => {
    const batchActions = batches?.reduce(
      (acc: EventActionBatch["actions"], batch: EventActionBatch) => {
        return [...acc, ...(batch?.actions ?? [])];
      },
      []
    );

    const allActions = [...(event?.actions ?? []), ...batchActions];

    return (
      allActions?.filter((action) => {
        return eventEntity.id === action.id;
      })[0]?.resourceStates ?? []
    );
  }, [event?.actions, eventEntity.id, batches]);

  const awaitResourceStates = useCallback(() => {
    return shouldAwaitResourceStates(eventEntity, updatedResourceStates);
  }, [eventEntity, updatedResourceStates]);

  const resourceStates = attempt
    ? attempt?.resourceStates
    : updatedResourceStates;

  useEffect(() => {
    if (awaitResourceStates()) {
      startPolling(2000);
    } else {
      stopPolling();
    }
  }, [awaitResourceStates, startPolling, stopPolling]);

  return (
    <Box innerSpacing="spacing00">
      <EventDetailsTabCtxProvider
        value={{ eventAction: eventEntity, attempt, setAttempt }}
      >
        <Row>
          <Col xs={8}>
            <FlexContainer
              direction="row"
              justifyContent="space-between"
              padding={`${theme.spacing.spacing04} 0`}
            >
              <div>
                <Text width="100%" align="left" size="md" weight="medium">
                  Actions
                </Text>
              </div>
              <div>
                <When condition={isResourceScope(eventEntity)}>
                  <Switch
                    name="label-left"
                    position="left"
                    labelText="Report View"
                    onChange={toggleReportView}
                  />
                </When>
              </div>
            </FlexContainer>
          </Col>
        </Row>
        <Row>
          <Col xs={4} sm={8} md={3} lg={3}>
            <FlexContainer direction="column" justifyContent="flex-start">
              {sortedItems.map((item) => (
                <If condition={item.isBatch} key={item.id}>
                  <Then>
                    <WrapMax css={{ paddingTop: `${theme.spacing.spacing03}` }}>
                      <EventActionBatchItem
                        actionBatch={item}
                        active={eventEntity?.id === item.id}
                        onClick={() => {
                          setEventEntity(item);
                          setAttempt(undefined);
                        }}
                      >
                        {item.actions?.map((batchAction: BatchAction) => (
                          <EventEntityItem
                            key={batchAction.id}
                            item={batchAction}
                            onMouseDown={() => setEventEntity(batchAction)}
                            {...eventEntityItemProps}
                          />
                        ))}
                      </EventActionBatchItem>
                    </WrapMax>
                  </Then>

                  <Else>
                    <WrapMax css={{ marginLeft: `${theme.spacing.spacing04}` }}>
                      <EventEntityItem
                        key={item.id}
                        item={item}
                        onMouseDown={() => {
                          setEventEntity(item);
                        }}
                        {...eventEntityItemProps}
                      />
                    </WrapMax>
                  </Else>
                </If>
              ))}
            </FlexContainer>
          </Col>
          <Col xs={4} sm={8} md={5} lg={9}>
            <Box boxStyle="grey" innerSpacing="spacing04">
              <FlexContainer alignItems="flex-start">
                <If condition={isReportView}>
                  <Then>
                    <ReportView
                      eventId={eventCtx.id}
                      eventActionId={eventEntity.id}
                      attempt={attempt}
                    />
                  </Then>
                  <Else>
                    <EventActionBox
                      eventEntity={eventEntity}
                      resourceStates={resourceStates}
                      planId={eventCtx?.plan?.id}
                      attempt={attempt}
                    />
                  </Else>
                </If>
              </FlexContainer>
            </Box>
          </Col>
        </Row>
      </EventDetailsTabCtxProvider>
    </Box>
  );
}
